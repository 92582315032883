<template>
  <div
    class="column is-three-quarters pt-5 px-3 addy-plus-side-menu-wrap is-relative"
    v-show="!isDashboard && !isNewOrEditAccount && showSideMenu"
  >
    <aside class="menu">
      <ul class="menu-list">
        <li v-for="item in corporationsForSideMenu" :key="item.id">
          <a :class="activeCorporation === item.id ? 'is-active' : ''" @click.prevent="toCorporationPage(item.id)">{{item.name}}</a>
          <ul :class="activeCorporation === item.id && canReadProperties ? '' : 'is-hidden'">
            <li>
              <div class="is-flex is-justify-content-space-between">
                <a @click.prevent="toPropertiesPage" :class="isPropertiesLevel ? 'is-active' : ''">Properties</a>
                <a @click.prevent="createNewProperty">
                  <b-icon pack="fa" icon="plus" size="is-small"></b-icon>
                </a>
              </div>
              <ul>
                <li v-for="property in item.properties" :key="property.id">
                  <a :class="activeProperty === property.id ? 'is-active' : ''" @click.prevent="toPropertyOverviewPage(property.id)">{{property.nickname}}</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="mt-5 pb-6">
          <a @click="addCorporation" v-if="activeAccount" class="add-entity">
            <span class="pr-2 has-text-primary has-text-weight-semibold">Add Entity</span>
            <b-icon pack="fa" icon="plus" size="is-small"></b-icon>
          </a>
        </li>
      </ul>
    </aside>
    <section class="collapse is-flex is-justify-content-center is-align-items-center py-3 is-clickable has-background-white" @click="hideMenu">
      <img src="@assets/icons/circle-left.svg" alt="left">
      <span class="has-text-blue has-text-weight-semibold ml-4">Collapse</span>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'addyPlusBaseSideMenu',
  computed: {
    ...mapState('addyPlusBase', ['corporationsForSideMenu', 'activeAccount', 'showSideMenu', 'permissions']),
    isDashboard() {
      return this.$route.path === '/addyPlus/dashboard'
    },
    isNewOrEditAccount() {
      return this.$route.name === 'createOrEditAddyPlusAccount'
    },
    // for highlighting the active corporation bar
    activeCorporation() {
      const { corporationId } = this.$route.params
      return corporationId || ''
    },
    // for highlighting the properies bar
    isPropertiesLevel() {
      const path = this.$route.path
      const corporationId = this.$route.params.corporationId
      if (!corporationId) return false
      const route = path.split(corporationId)[1]
      return route.startsWith('/properties')
    },
    // for highlighting the ative property bar
    activeProperty() {
      const { propertyId } = this.$route.params
      return propertyId || ''
    },
    canReadProperties() {
      return this.permissions.asset?.includes('read')
    },
  },
  created() {
    this.getSideMenuToggleStatus()
  },
  methods: {
    ...mapMutations('addyPlusBase', ['setSideMenuToggleStatus']),
    getSideMenuToggleStatus() {
      const flag = sessionStorage.getItem('showAddyPlusSideMenu')
      this.setSideMenuToggleStatus(flag ? JSON.parse(flag) : true)
    },
    hideMenu() {
      this.setSideMenuToggleStatus(false)
    },
    createNewAccount() {
      this.$router.push('/addyPlus/account/new')
    },
    toCorporationPage(id) {
      this.$router.push(`/addyPlus/${this.activeAccount}/corporations/${id}`)
    },
    toPropertiesPage() {
      this.$router.push(`/addyPlus/${this.activeAccount}/corporations/${this.activeCorporation}/properties`)
    },
    createNewProperty() {
      this.$router.push(`/addyPlus/${this.activeAccount}/corporations/${this.activeCorporation}/properties/new`)
    },
    toPropertyOverviewPage(propertyId) {
      this.$router.push(`/addyPlus/${this.activeAccount}/corporations/${this.activeCorporation}/properties/${propertyId}`)
    },
    addCorporation() {
      this.$router.push(`/addyPlus/${this.activeAccount}/corporations/new`)
    },
    toCsrPage() {
      this.$router.push(`/addyPlus/${this.activeAccount}/corporations/${this.activeCorporation}/csr`)
    },
  }
}
</script>
<style lang="scss" scoped>
.addy-plus-side-menu-wrap {
  font-size: 14px;
  width: 270px;
  border-right: 1px solid lightgrey;
  overflow-y: auto;
  .menu {
    min-height: calc(100% - 40px);
  }
  .for-dashboard.is-hover-pointer:hover {
    background-color: var(--addy-blue-light-3);
  }
  a {
    background: none !important;
    &.is-active {
      color: var(--addy-blue) !important;
      font-weight: 600;
    }
    &:hover {
      background-color: var(--addy-blue-light-3) !important;;
    }
  }
  .add-entity {
    ::v-deep i {
      color: #D79133;
    }
  }
  .collapse {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    &:hover {
      opacity: 0.5;
    }
    span {
      font-size: 16px;
    }
  }
}
</style>

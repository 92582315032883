<template>
  <div id="addy-plus-base">
    <AddyPlusHeader/>
    <div class="is-flex addy-plus-body">
      <aside class="fix-column is-one-quarter is-hidden-mobile">
        <div class="columns is-fullheight">
          <div
            :class="`accounts-menu column is-one-quarter has-background-white-light-blue is-flex is-flex-direction-column is-align-items-center pt-5 is-relative ${showSideMenu ? '' : 'collapsed'}`"
          >
            <a
              v-for="account in accounts"
              :key="account.accountId"
              :class="`account-logo button router-link-active mb-3${$route.params.accountId === account.id ? ' is-active' : ''}`"
              type="button"
              @click.prevent="checkAccount(account.id, account.logo)"
            >
              <div :style="`background: url(${account.logo}) center/contain no-repeat`" class="is-fullwidth is-fullheight"></div>
            </a>
            <!-- <a class="mt-5 addNewAccountBtn" @click="createNewAccount">
              <b-icon pack="fas" icon="plus" size="is-medium"></b-icon>
            </a> -->
            <section
              class="show-menu is-justify-content-center is-align-items-center py-3 is-clickable"
              v-show="!showSideMenu"
              @click="expandSideMenu"
            >
              <img src="@assets/icons/circle-right.svg" alt="arrow right">
            </section>
          </div>
          <AddyPlusSideMenu ref="addyPlusSideMenu"/>
        </div>
      </aside>
      <main class="flex-column is-three-quarters py-5 px-5" id="addy-plus-main-container">
        <router-view></router-view>
      </main>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import AddyPlusHeader from './Header'
import AddyPlusSideMenu from './SideMenu'
import { fetchAllAccounts, fetchAccountAdminPermissions } from '@api/addy-plus/accounts'
import { fetchCorporations as fetchCorps } from '@api/addy-plus/corporations'
import { fetchProperties as fetchProps } from '@api/addy-plus/properties'
import { ascSort } from '@utils/common-methods/common'

export default {
  name: 'addyPlusBase',
  components: {
    AddyPlusHeader,
    AddyPlusSideMenu,
  },
  computed: {
    ...mapState('addyPlusBase', ['accounts', 'corporations', 'corporationsForSideMenu', 'showSideMenu', 'permissions', 'activeAccount']),
    ...mapGetters('addyPlusBase', ['parsedAccounts']),
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
  },
  beforeRouteUpdate(to, from, next) { // update logo in the action of history back, too
    const accountId = to.params.accountId
    const corporationId = to.params.corporationId
    const logoUrl = accountId ? this.parsedAccounts[accountId].logo : 'https://addy-public.s3.us-west-2.amazonaws.com/logo_prussian_blue.svg'
    this.setLogo(logoUrl)

    if (accountId && corporationId && corporationId !== from.params.corporationId) {
      this.fetchPermissions(accountId, corporationId)
    }

    if (this.isPropertiesLevel(to.path)) this.fetchProperties(corporationId)
    else this.removePropsFromCorp(corporationId)

    next()
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapMutations('addyPlusBase', ['setAccounts', 'setLogo', 'setActiveAccount', 'setCorporationsForSideMenu', 'setSideMenuToggleStatus', 'setPermissions']),
    ...mapActions('addyPlusBase', ['setCorporations']),
    fetchData() {
      fetchAllAccounts().then((result) => {
        if (!result.success) return
        this.setAccounts(result.data)

        if (this.accountId) {
          this.setActiveAccount(this.accountId)
          this.setLogo(this.parsedAccounts[this.accountId].logo)
          this.fetchCorporations(this.accountId)
          this.corporationId && this.fetchPermissions(this.accountId, this.corporationId)
        }
      })
    },
    fetchPermissions(accountId, corporationId) {
      fetchAccountAdminPermissions(accountId, corporationId).then((result) => {
        if (!result.success) return
        this.setPermissions(result.data.permissions)
      })
    },
    fetchCorporations(accountId) {
      fetchCorps(accountId).then(async (result) => {
        if (!result.success) return
        const corporations = ascSort(result.data, 'name')
        await this.setCorporations(corporations)

        if (this.isPropertiesLevel(this.$route.path)) {
          this.fetchProperties(this.corporationId)
        }
      })
    },
    fetchProperties(corporationId) {
      if (this.corporationsForSideMenu[corporationId].properties) return

      fetchProps(corporationId).then((result) => {
        if (!result.success) return
        this.savePropsToCorp(corporationId, result.data)
      })
    },
    savePropsToCorp(corporationId, props) {
      const corp = JSON.parse(JSON.stringify(this.corporationsForSideMenu[corporationId]))
      if (corp.properties) return

      corp.properties = props
      this.setCorporationsForSideMenu({
        ...this.corporationsForSideMenu,
        [corporationId]: corp,
      })
    },
    removePropsFromCorp(corporationId) {
      if (!corporationId) return
      const corps = JSON.parse(JSON.stringify(this.corporationsForSideMenu))
      if (!corps[corporationId]?.properties) return

      delete corps[corporationId].properties
      this.setCorporationsForSideMenu(corps)
    },
    checkAccount(accountId, logoUrl) {
      this.setActiveAccount(accountId)
      this.$router.push(`/addyPlus/${accountId}/corporations`)
    },
    createNewAccount() {
      this.$router.push('/addyPlus/account/new')
    },
    isPropertiesLevel(path) {
      const regEx = new RegExp(`.+${this.corporationId}/properties.*`)
      return this.corporationId && regEx.test(path)
    },
    expandSideMenu() {
      this.setSideMenuToggleStatus(true)
    },
  }
}
</script>
<style lang="scss" scoped>
$container-height: calc(100vh - 52px);

#addy-plus-base {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: #fff;
  .addy-plus-body {
    height: $container-height;
    .fix-column {
      .accounts-menu {
        width: 80px;
        transition: all 1s;
        &.collapsed {
          width: 50px;
          .account-logo {
            scale: 0.6;
          }
          .addNewAccountBtn {
            scale: 0.8;
          }
        }
        .show-menu {
          display: flex;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
    .flex-column {
      flex: 1;
    }
    .account-logo {
      transition: all 1s;
      width: 50px;
      height: 50px;
      padding: 6px;
      border-width: 2px;
      border-radius: 14px;
      > div {
        border-radius: 8px;
      }
    }
  }
  .columns,
  .column {
    padding: 0;
    margin: 0;
    &.has-background-white-light-blue {
      background-color: var(--addy-blue-light-3);
    }
  }
  main.flex-column {
    height: $container-height;
    overflow: auto;
  }
  a.addNewAccountBtn {
    transition: all 1s;
    .icon ::v-deep i {
      font-size: 28px;
      color: var(--addy-blue-light-2);
    }
    &:hover {
      .icon ::v-deep i {
        color: var(--addy-blue);
      }
    }
  }
  .button.is-active {
    border-color: var(--addy-blue);
  }
}
</style>

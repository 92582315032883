import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * Fetch properties
 * @param {String} corporationId
 * @return {Promise}
 */
export const fetchProperties = (corporationId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/properties?corporation_id=${corporationId}`))
}

/**
 * create new property
 * @param {Object} params
 * @return {Promise}
 */
export const createProperty = (params) => {
  return axios.post(getImbyRoute('/api/addy_plus/v1/properties'), { property: params })
}

/**
 * fetch details of property
 * @param {String} propertyId
 * @return {Promise}
 */
export const fetchPropertyDetails = (propertyId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/properties/${propertyId}`))
}

/**
 * update property
 * @param {String} propertyId
 * @param {Object} params
 * @return {Promise}
 */
export const updateProperty = (propertyId, params) => {
  return axios.patch(getImbyRoute(`/api/addy_plus/v1/properties/${propertyId}`), { property: params })
}

/**
 * fetch details of property
 * @param {String} propertyId
 * @return {Promise}
 */
export const fetchPropertyDueDiligenceFiles = (propertyId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/properties/${propertyId}/due_diligence`))
}

/**
 * Mass upload property due diligence files / delete file / update file
 * @param {String} propertyId
 * @param {Object} formData
 * @param {Function} onUploadProgress
 * @return {Promise}
 */
export const handlePropertyDueDiligenceFiles = (propertyId, formData, onUploadProgress) => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/properties/${propertyId}/due_diligence`), formData, {
    onUploadProgress
  })
}

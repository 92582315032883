<template>
  <b-navbar>
    <template slot="brand">
      <b-navbar-item @click="onLogoClick">
        <img class="logo" :src="logoUrl" alt="addy">
      </b-navbar-item>
    </template>
    <template slot="end">
      <div id="addy-business-permission-display" class="is-flex is-align-items-center"></div>
      <div class="is-relative ml-6">
        <div
          class="profile-panel is-flex is-align-items-center"
          @mouseover="profilePanelActive = true"
          @mouseleave="profilePanelActive = false"
        >
          <div class="profile-icon light-purple-button is-flex is-justify-content-center is-align-items-end">
            <img src="@assets/images/common/profile-icon.svg" alt="avatar" class="avatar">
          </div>
          <img src="@assets/icons/arrow-up.svg" alt="arrow up" v-if="profilePanelActive">
          <img src="@assets/icons/arrow-down.svg" alt="arrow down" v-else>
        </div>
        <section
          class="pop-up has-background-white"
          v-show="profilePanelActive"
          @mouseover="profilePanelActive = true"
          @mouseleave="profilePanelActive = false"
        >
          <a href="/dashboard" class="mb-2">addy Personal</a>
          <a @click="onSignOut">Sign out</a>
        </section>
      </div>
    </template>
  </b-navbar>
</template>
<script>
import { mapState } from 'vuex'
import { logOutAndCleanSession } from '@utils/common-methods/auth'

export default {
  data() {
    return {
      profilePanelActive: false,
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['logoUrl'])
  },
  methods: {
    onLogoClick() {
      this.$router.push('/addyPlus/dashboard')
    },
    onSignOut() {
      logOutAndCleanSession().then(() => this.$router.push('/addyPlus/login'))
    },
  }
}
</script>
<style lang="scss" scoped>
.navbar {
  box-shadow: 0 5px 20px var(--addy-blue-light-1);
  background-color: #fff;
  padding-left: 5rem;
  padding-right: 40px;
  font-size: 16px;
  .logo {
    width: 100%;
  }
}
.profile-panel {
  height: 100%;
  padding-right: 6PX;
  .profile-icon {
    overflow: hidden;
    margin-right: 10PX;
    width: 42PX;
    height: 42PX;
    border-radius: 50%;
    .avatar {
      width: 25PX;
    }
  }
}
.pop-up {
  position: absolute;
  right: 0;
  top: 51PX;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  font-size: 16PX;
  padding: 10PX 20PX 10PX 0;
  border-radius: 8PX;
  z-index: 100;
  > a {
    white-space: nowrap;
    padding: 4PX 12PX;
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .navbar {
    padding: 0;
  }
}
</style>

import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * Fetch addy plus accounts
 * @return {Promise}
 */
export const fetchAllAccounts = () => {
  return axios.get(getImbyRoute('/api/addy_plus/v1/accounts'))
}

/**
 * Fetch addy plus account
 * @param {String} accountId
 * @return {Promise}
 */
export const fetchAccount = (accountId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}`))
}

/**
 * Update addy plus account details
 * @param {String} accountId
 * @param {Object} formData
 * @return {Promise}
 */
export const updateAccount = (accountId, formData) => {
  return axios.patch(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}`), formData)
}

/**
 * Fetch addy plus account admin permissions
 * @param {String} accountId
 * @param {String} corporationId
 * @return {Promise}
 */
export const fetchAccountAdminPermissions = (accountId, corporationId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}/corporations/${corporationId}/permissions`))
}

/**
 * Fetch dealer disclaimer
 * @param {String} accountId
 * @return {Promise}
 */
export const fetchDealerDisclaimer = (accountId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}/dealer_disclaimer`))
}

/**
 * Save dealer disclaimer
 * @param {String} accountId
 * @param {String} disclaimer
 * @return {Promise}
 */
export const saveDealerDisclaimer = (accountId, disclaimer) => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}/dealer_disclaimer`), { disclaimer })
}
